import useAvailability from 'graphql/hooks/useAvailability';
import useLocations from 'graphql/hooks/useLocations';
import useSpaceCategories from 'graphql/hooks/useSpaceCategories';

const useConnect = () => {
  const { loading: isSpacesLoading, spaces } = useAvailability({
    canInstantBooking: true,
    take: 8
  });

  const { loading: isLocationsLoading, locations } = useLocations();
  locations.list = locations.list.slice(0, 4);
  locations.count = 4;

  const {
    loading: isSpaceCategoriesLoading,
    spaceCategories,
  } = useSpaceCategories({
    start: 0,
    limit: 8,
  });

  const instantSpaces = [] as typeof spaces;

  spaces.forEach(
      (space: any)=>{
        instantSpaces.push(space)
      }
  )

  return {
    isSpacesLoading,
    isLocationsLoading,
    isSpaceCategoriesLoading,
    spaces,
    locations,
    spaceCategories,
    instantSpaces
  };
};

export default useConnect;
